import React from "react";

function Footer() {
  return (
    <>
      <div className="bg-footer grid-container footer">
        <div className="link-footer link-1">
          <a href="https://game.lsm99queen.bet/login "></a>
        </div>
        <div className="link-footer link-2">
          <a href="https://game.lsm99queen.bet/login "></a>
        </div>
        <div className="link-footer link-3">
          <a href="https://game.lsm99queen.bet/login "></a>
        </div>
        <div className="link-footer link-4">
          <a href="https://game.lsm99queen.bet/login "></a>
        </div>
        <div className="link-footer link-5">
          <a href="/promotion"></a>
        </div>
      </div>
    </>
  );
}

export default Footer;
