import React from "react";
import { Col, Row } from "antd";
import { css } from "@emotion/css";
const menuPlayGame = "https://fs.cdnxn.com/landingQueen/Images/all-game.png";
const menuPlayGameMB = "https://fs.cdnxn.com/landingQueen/Images/all-game-mb.png";
const menuSlot = "https://fs.cdnxn.com/landingQueen/Images/Game/slot.gif";
const menuSport = "https://fs.cdnxn.com/landingQueen/Images/Game/sport.gif";
const menuCasino = "https://fs.cdnxn.com/landingQueen/Images/Game/casino.gif";
const menuLotto = "https://fs.cdnxn.com/landingQueen/Images/Game/lotto.gif";
const HeadGame = "https://fs.cdnxn.com/landingQueen/Images/head-home-2.png";

function contentMenu() {
  return (
    <>
      <div className="box-center">
        <div className="box-img-head">
          <img src={HeadGame} alt="" className="img-100" />
        </div>
      </div>
      <Row justify="center" align="middle" className="content-menu-row">
        <Col xs={10} sm={9} lg={6}>
          <a href="https://game.lsm99queen.bet/login ">
            <img
              src={menuSlot}
              className={css`
                width: 100%;
                &:hover,
                :focus {
                  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
                  transform: translateY(-0.5em);
                }
              `}
              alt="..."
            />
          </a>
        </Col>
        <Col xs={10} sm={9} lg={6}>
          <a href="https://game.lsm99queen.bet/login ">
            <img
              src={menuSport}
              className={css`
                width: 100%;
                &:hover,
                :focus {
                  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
                  transform: translateY(-0.5em);
                }
              `}
              alt="..."
            />
          </a>
        </Col>
        <Col xs={10} sm={9} lg={6}>
          <a href="https://game.lsm99queen.bet/login ">
            <img
              src={menuCasino}
              className={css`
                width: 100%;
                &:hover,
                :focus {
                  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
                  transform: translateY(-0.5em);
                }
              `}
              alt="..."
            />
          </a>
        </Col>
        <Col xs={10} sm={9} lg={6}>
          <a href="https://game.lsm99queen.bet/login ">
            <img
              src={menuLotto}
              className={css`
                width: 100%;
                &:hover,
                :focus {
                  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
                  transform: translateY(-0.5em);
                }
              `}
              alt="..."
            />
          </a>
        </Col>
      </Row>
      <div className="pc">
        <a href="https://game.lsm99queen.bet/login " className="box-center">
          <img
            src={menuPlayGame}
            className={css`
                width: 40%;
                margin-top: 10px;
                &:hover,
                :focus {
                  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
                  transform: translateY(-0.5em);
                }
              `}
            alt="..."
          />
        </a>
      </div>
      <div className="mb">
        <a href="https://game.lsm99queen.bet/login " className="box-center">
          <img
            src={menuPlayGameMB}
            className={css`
                width: 70%;
                &:hover,
                :focus {
                  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
                  transform: translateY(-0.5em);
                }
              `}
            alt="..."
          />
        </a>
      </div>
    </>
  );
}

export default contentMenu;
