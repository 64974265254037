import React from "react";
import { Carousel } from "antd";
// import { css } from "@emotion/css";
const HeadPromotion = "https://fs.cdnxn.com/landingQueen/Images/head-home-1.png";
const Slot1 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/slot1.png";
const Slot2 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/slot2.png";
const Sport5 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/sport5.png";
const Sport6 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/sport6.png";
const Sport2 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/sport2.png";

const contentStyle = {
  width: "100%",
  textAlign: "center",
};

function carouselCard() {
  return (
    <>
      <div className="box-center">
        <div className="pc box-img-head">
          <img src={HeadPromotion} alt="" className="img-100" />
        </div>
      </div>
      <div>
        <Carousel autoplay>
          <div>
            <img style={contentStyle} src={Slot1} alt="..." />
          </div>
          <div>
            <img style={contentStyle} src={Slot2} alt="..." />
          </div>
          <div>
            <img style={contentStyle} src={Sport5} alt="..." />
          </div>
          <div>
            <img style={contentStyle} src={Sport6} alt="..." />
          </div>
          <div>
            <img style={contentStyle} src={Sport2} alt="..." />
          </div>
        </Carousel>
      </div>
    </>
  );
}

export default carouselCard;
