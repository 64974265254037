import React, { useState } from "react";
import HomeFooter from "../Footer/Footer";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Col, Row } from "antd";
import { css } from "@emotion/css";
import { Alert } from "antd";
import Marquee from "react-fast-marquee";
import CarouselCard from "./Component/carouselCard";
import ContentMenu from "./Component/contentMenu";
import HomeHeaderNav from "../Header/Header";
const Singin = "https://fs.cdnxn.com/landingQueen/Images/button-sigin.png";
const Login = "https://fs.cdnxn.com/landingQueen/Images/button-login.png";
const BtnClose = "https://fs.cdnxn.com/landingQueen/Images/Icon-Close.png";
const menuGameHot = "https://fs.cdnxn.com/landingQueen/Images/text-gamehot.png";
const menuLookAll = "https://fs.cdnxn.com/landingQueen/Images/text-lookall.png";
const Game1 = "https://fs.cdnxn.com/landingQueen/Images/Game/game1.png";
const Game2 = "https://fs.cdnxn.com/landingQueen/Images/Game/game2.png";
const Game3 = "https://fs.cdnxn.com/landingQueen/Images/Game/game3.png";
const Game4 = "https://fs.cdnxn.com/landingQueen/Images/Game/game4.png";
const Game5 = "https://fs.cdnxn.com/landingQueen/Images/Game/game5.png";
const Game6 = "https://fs.cdnxn.com/landingQueen/Images/Game/game6.png";
const Game7 = "https://fs.cdnxn.com/landingQueen/Images/Game/game7.png";


function Index() {
  const [close, setClose] = useState(true);

  const Gamelist = [
    { game: Game1 },
    { game: Game2 },
    { game: Game3 },
    { game: Game4 },
    { game: Game5 },
    { game: Game6 },
    { game: Game7 },
  ];

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 10,
    },
    desktop1: {
      breakpoint: { max: 3000, min: 1320 },
      items: 8,
    },
    desktop2: {
      breakpoint: { max: 1320, min: 720 },
      items: 6,
    },
    tablet1: {
      breakpoint: { max: 960, min: 720 },
      items: 4,
    },
    tablet2: {
      breakpoint: { max: 720, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  return (
    <>
      <div className="home-image home-footer-section">
        <HomeHeaderNav />
        <div className="mb">
          {close === true ? (
            <>
              <div className="alert-home">
                <div className="alert-marquee">
                  <Alert
                    message={
                      <Marquee pauseOnHover gradient={false} speed={70}>
                        ยินดีต้อนรับเข้าสู่ LSM99QUEEN เว็บออนไลน์อันดับ 1 ในไทย
                        มีทั้งบอล มวย หวย สล๊อต คาสิโน และ อื่นๆอีกมากมาย
                        เว็บตรง มั่นคง ปลอดภัย ฝาก-ถอน อัตโนมัติ
                        แอดมินคอยให้บริการลูกค้าตลอด 24 ชั่วโมง
                      </Marquee>
                    }
                  />
                  <img
                    src={BtnClose}
                    onClick={() => setClose(false)}
                    className="icon-close"
                    alt="..."
                  />
                </div>
              </div>
            </>
          ) : null}
        </div>
        <Row justify="center" align="top" className="home-section">
          <Col xs={20} lg={9}>
            <CarouselCard />
          </Col>
          <div className="mb my-2 mx-3">
            <div className="d-flex justify-content-center align-items-center ">
              <a href="https://game.lsm99queen.com/register" className="me-3">
                <img
                  src={Singin}
                  className={css`width:100%;
                      
                        }`}
                  alt="..."
                ></img>
              </a>
              <a href="https://game.lsm99queen.bet/login ">
                <img
                  src={Login}
                  className={css`width:100%;
                         
                        }`}
                  alt="..."
                ></img>
              </a>
            </div>
          </div>
          <Col xs={24} lg={9}>
            <ContentMenu />
          </Col>
        </Row>
        <div className="pc home-section">
          <div className="box-center">
            <div className="pc box-img-head">
              <img src={menuGameHot} className="img-100" alt="..." />
            </div>
          </div>
          <div className="carousel-game">
            <Carousel draggable={true} responsive={responsive}>
              {Gamelist.map((item) => (
                <a
                  href="https://game.lsm99queen.bet/login "
                  className="d-flex justify-content-center carousel-card"
                >
                  <img
                    src={item.game}
                    className="image-carousel-game"
                    alt="..."
                  ></img>
                </a>
              ))}
            </Carousel>
          </div>
          {/* <a
            href="https://game.lsm99queen.bet/login "
            className="text-look-all"
          >
            <img src={menuLookAll} className="img-look-all" alt="..." />
          </a> */}
          <a href="https://game.lsm99queen.bet/login " className="box-center">
            <img
              src={menuLookAll}
              className={css`
                width: 25%;
                &:hover,
                :focus {
                  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
                  transform: translateY(-0.5em);
                }
              `}
              alt="..."
            />
          </a>
        </div>
        <div className="mb">
          <HomeFooter />
        </div>
      </div>
    </>
  );
}

export default Index;
