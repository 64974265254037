import React, { useEffect, useState } from "react";
import classNames from "classnames";
import HomeHeaderNav from "../Header/Header";
import HomeFooter from "../Footer/Footer";

const NewPromotion = "https://fs.cdnxn.com/landingQueen/Images/head-home-1.png";
const btnBG = "https://fs.cdnxn.com/landingQueen/Images/btn-bg.png";
const Casino1 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/casino1.png";
const Casino2 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/casino2.png";
const Casino3 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/casino3.png";
const Casino4 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/casino4.png";
const Casino8 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/casino8.png";

const Slot1 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/slot1.png";
const Slot2 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/slot2.png";
const Slot3 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/slot3.png";
const Slot4 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/slot4.png";
const Slot5 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/slot5.png";
const Slot6 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/slot6.png";
const Slot7 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/slot7.png";

const Lotto1 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/lotto1.png";

const Sport1 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/sport1.png";
const Sport2 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/sport2.png";
const Sport3 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/sport3.png";
const Sport4 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/sport4.png";
const Sport5 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/sport5.png";
const Sport6 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/sport6.png";
const Sport7 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/sport7.png";
const Sport8 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/sport8.png";
const Sport9 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/sport9.png";
const Sport10 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/sport10.png";
const Sport11 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/sport11.png";
const Sport12 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/sport12.png";
const Sport13 = "https://fs.cdnxn.com/landingQueen/Images/Promotion/sport13.png";

export default function PromotionPage() {
  const [bannerPromotionList, setBannerPromotionList] = useState([]);
  const [bannerPromotion, setBannerPromotion] = useState([]);
  const [proType, setProType] = useState("all");

  const [bannerPro] = useState([
    {
      imageUrl: Casino8,
      type: "casino8",
    },
    {
      imageUrl: Casino1,
      type: "casino",
    },
    {
      imageUrl: Casino2,
      type: "casino",
    },
    {
      imageUrl: Casino3,
      type: "casino",
    },
    {
      imageUrl: Casino4,
      type: "casino",
    },

    {
      imageUrl: Sport1,
      type: "sport",
    },
    {
      imageUrl: Sport2,
      type: "sport",
    },
    // {
    //   imageUrl: Sport3,
    //   type: "sport",
    // },
    {
      imageUrl: Sport4,
      type: "sport",
    },
    {
      imageUrl: Sport5,
      type: "sport",
    },
    {
      imageUrl: Sport6,
      type: "sport",
    },
    {
      imageUrl: Sport7,
      type: "sport",
    },
    {
      imageUrl: Sport8,
      type: "sport",
    },
    {
      imageUrl: Sport9,
      type: "sport",
    },
    {
      imageUrl: Sport10,
      type: "sport",
    },
    {
      imageUrl: Sport11,
      type: "sport",
    },
    {
      imageUrl: Sport12,
      type: "sport",
    },
    {
      imageUrl: Sport13,
      type: "sport",
    },
    {
      imageUrl: Slot1,
      type: "slot",
    },
    {
      imageUrl: Slot2,
      type: "slot",
    },
    {
      imageUrl: Slot3,
      type: "slot",
    },
    {
      imageUrl: Slot4,
      type: "slot",
    },

    // {
    //   imageUrl: Slot5,
    //   type: "slot",
    // },
    {
      imageUrl: Slot6,
      type: "slot",
    },
    {
      imageUrl: Slot7,
      type: "slot",
    },
    {
      imageUrl: Lotto1,
      type: "lotto",
    },
  ]);

  useEffect(() => {
    setBannerPromotionList(bannerPro);
    setBannerPromotion(bannerPro);
  }, [bannerPro]);

  const updatePromotion = (proType) => {
    const promotionFilter = bannerPromotionList.filter(
      (i) => i.type === proType
    );
    setBannerPromotion(promotionFilter);
    setProType(proType);
  };

  const updatePromotionAll = (proType) => {
    setBannerPromotion(bannerPromotionList);
    setProType(proType);
  };

  return (
    <div className="div-main-promotion-page promotion-bg-image space-promotion">
      <HomeHeaderNav />
      <div className="session-title-promotion">
        <img src={NewPromotion} className="img-promotion-memu" alt="..." />
      </div>

      <div className="seesion-title-catgory">
        <div
          className={classNames("pro-item", {
            "active-promotion-category": proType === "all",
          })}
          onClick={() => {
            updatePromotionAll("all");
          }}
        >
          <p className="mb-0">
            <b>ทั้งหมด</b>
          </p>
        </div>
        <div
          className={classNames("pro-item", {
            "active-promotion-category": proType === "sport",
          })}
          onClick={() => {
            updatePromotion("sport");
          }}
        >
          <p className="mb-0">
            <b>กีฬา</b>
          </p>
        </div>
        <div
          className={classNames("pro-item", {
            "active-promotion-category": proType === "casino",
          })}
          onClick={() => {
            updatePromotion("casino");
          }}
        >
          <p className="mb-0">
            <b>คาสิโน</b>
          </p>
        </div>
        <div
          className={classNames("pro-item", {
            "active-promotion-category": proType === "slot",
          })}
          onClick={() => {
            updatePromotion("slot");
          }}
        >
          <p className="mb-0">
            <b>สล็อต</b>
          </p>
        </div>
        <div
          className={classNames("pro-item", {
            "active-promotion-category": proType === "lotto",
          })}
          onClick={() => {
            updatePromotion("lotto");
          }}
        >
          <p className="mb-0">
            <b>หวย</b>
          </p>
        </div>
      </div>
      <div className="session-banner-promotion">
        {bannerPromotion.map((row, index) => (
          <div className="box-banner-promotion" key={index}>
            <a href="https://game.lsm99queen.bet/login ">
              <img
                src={row.imageUrl}
                draggable={false}
                className="image-promotion"
                alt={row.type}
              />
            </a>
          </div>
        ))}
      </div>
      <div className="box-center">
        <div class="box-img-head">
          <img src={btnBG} className="img-100" alt="..."></img>
        </div>
      </div>
      <div className="mb">
        <HomeFooter />
      </div>
    </div>
  );
}
