import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { MdClose } from "react-icons/md";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import classNames from "classnames";

const Wallet = "https://fs.cdnxn.com/landingQueen/Images/link-item/p-a1.png";
const Deposit = "https://fs.cdnxn.com/landingQueen/Images/link-item/p-a2.png";
const PlayGame = "https://fs.cdnxn.com/landingQueen/Images/link-item/p-a3.png";
const Withdraw = "https://fs.cdnxn.com/landingQueen/Images/link-item/p-a4.png";
const Promotion = "https://fs.cdnxn.com/landingQueen/Images/link-item/p-a5.png";

const HoverWallet = "https://fs.cdnxn.com/landingQueen/Images/link-item/a-a1.gif";
const HoverDeposit = "https://fs.cdnxn.com/landingQueen/Images/link-item/a-a2.gif";
const HoverPlayGame = "https://fs.cdnxn.com/landingQueen/Images/link-item/a-a3.gif";
const HoverWithdraw = "https://fs.cdnxn.com/landingQueen/Images/link-item/a-a4.gif";
const HoverPromotion = "https://fs.cdnxn.com/landingQueen/Images/link-item/a-a5.gif";

const BurgerMenu = "https://fs.cdnxn.com/landingQueen/Images/burger-menu.png";
const Notification = "https://fs.cdnxn.com/landingQueen/Images/notification.png";
const Line = "https://fs.cdnxn.com/landingQueen/Images/line.png";
const Singin = "https://fs.cdnxn.com/landingQueen/Images/button-sigin.png";
const Login = "https://fs.cdnxn.com/landingQueen/Images/button-login.png";
// const LogoMB = "https://fs.cdnxn.com/landingQueen/Images/logo-mb.png";
// เทศกาล
const LogoMB = "https://fs.cdnxn.com/landingQueen/Images/logo-mb.png";

const BurgerAlliance = "https://fs.cdnxn.com/landingQueen/Images/BurgerAlliance.png";
const BurgerContact = "https://fs.cdnxn.com/landingQueen/Images/BurgerContact.png";
const BurgerDownload = "https://fs.cdnxn.com/landingQueen/Images/BurgerDownload.png";
const BurgerHistory = "https://fs.cdnxn.com/landingQueen/Images/BurgerHistory.png";
const BurgerMoneyback = "https://fs.cdnxn.com/landingQueen/Images/BurgerMoneyback.png";
const BurgerPlayGame = "https://fs.cdnxn.com/landingQueen/Images/BurgerPlayGame.png";
const BurgerPromotion = "https://fs.cdnxn.com/landingQueen/Images/BurgerPromotion.png";

const btnBG = "https://fs.cdnxn.com/landingQueen/Images/btn-bg.png";
const TitleNotification = "https://fs.cdnxn.com/landingQueen/Images/title-notification.png";
const BGNoti = "https://fs.cdnxn.com/landingQueen/Images/bg-burger-noti.png";
const Logout = "https://fs.cdnxn.com/landingQueen/Images/btn-logout.png";

function Header() {
  const [bannerPromotion, setBannerPromotion] = useState([]);
  const [proType, setProType] = useState("alls");

  const [bannerPro] = useState([
    {
      text: "คุณไม่มีข้อความ",
      type: "alls",
    },
    {
      text: "คุณไม่มีข้อความ",
      type: "deposit",
    },
    {
      text: "คุณไม่มีข้อความ",
      type: "withdrawn",
    },
    {
      text: "คุณไม่มีข้อความ",
      type: "bonus",
    },
  ]);

  const [bannerPromotionList, setBannerPromotionList] = useState([
    bannerPro[0],
  ]);

  useEffect(() => {
    setBannerPromotionList(bannerPro);
    setProType("alls");
    const promotionFilter = bannerPromotionList.filter(
      (i) => i.type === "alls"
    );
    setBannerPromotion(promotionFilter);
  }, [bannerPro, bannerPromotionList]);

  const updatePromotion = (proType) => {
    const promotionFilter = bannerPromotionList.filter(
      (i) => i.type === proType
    );
    setBannerPromotion(promotionFilter);
    setProType(proType);
  };
  return (
    <>
      <div className="bg-navbar">
        {/* Header PC */}
        <Container className="pc">
          <Row>
            <Col xs={3} className="navbar-left">
              <a href="#">
                <img
                  data-toggle="modal"
                  data-target="#exampleModal1"
                  className="left-navbar icon-group1"
                  src={Notification}
                  alt=""
                />
              </a>
              <a href="#">
                <img
                  data-toggle="modal"
                  data-target="#exampleModal"
                  className="left-navbar icon-group1"
                  src={BurgerMenu}
                  alt=""
                />
              </a>
              <a href="https://line.me/R/ti/p/@lsm99queen">
                <img src={Line} alt="" className="left-navbar icon-group2" />
              </a>
            </Col>
            <Col xs={6} className="navbar-center">
              <a href="https://game.lsm99queen.bet/login ">
                <img
                  src={Wallet}
                  onMouseOver={(e) => (e.currentTarget.src = HoverWallet)}
                  onMouseLeave={(e) => (e.currentTarget.src = Wallet)}
                  alt=""
                  className="main-navbar icon-group3 main-icon"
                />
              </a>
              <a href="https://game.lsm99queen.bet/login ">
                <img
                  src={Deposit}
                  onMouseOver={(e) => (e.currentTarget.src = HoverDeposit)}
                  onMouseLeave={(e) => (e.currentTarget.src = Deposit)}
                  alt=""
                  className="main-navbar icon-group4 main-icon"
                />
              </a>
              <a href="https://game.lsm99queen.bet/login ">
                <img
                  src={PlayGame}
                  onMouseOver={(e) => (e.currentTarget.src = HoverPlayGame)}
                  onMouseLeave={(e) => (e.currentTarget.src = PlayGame)}
                  alt=""
                  className="main-navbar icon-group5 main-icon"
                />
              </a>
              <a href="https://game.lsm99queen.bet/login ">
                <img
                  src={Withdraw}
                  onMouseOver={(e) => (e.currentTarget.src = HoverWithdraw)}
                  onMouseLeave={(e) => (e.currentTarget.src = Withdraw)}
                  alt=""
                  className="main-navbar icon-group6 main-icon"
                />
              </a>
              <a href="/promotion">
                <img
                  src={Promotion}
                  onMouseOver={(e) => (e.currentTarget.src = HoverPromotion)}
                  onMouseLeave={(e) => (e.currentTarget.src = Promotion)}
                  alt=""
                  className="main-navbar icon-group7 main-icon"
                />
              </a>
            </Col>
            <Col xs={3} className="navbar-right">
              <a href="https://game.lsm99queen.bet/login ">
                <img src={Login} alt="" className="icon-group8" />
              </a>
              <a href="https://game.lsm99queen.com/register">
                <img src={Singin} alt="" className="icon-group8" />
              </a>
            </Col>
          </Row>
        </Container>

        {/* Header Mobile */}
        <Container className="mb">
          <Row>
            <Col xs={4} className="icon-navbar navbar-left">
              <img
                data-toggle="modal"
                data-target="#exampleModal"
                className="left-navbar icon-group1 mt-icon"
                src={BurgerMenu}
                alt=""
              />
              <img
                data-toggle="modal"
                data-target="#exampleModal1"
                className="left-navbar icon-group2"
                src={Notification}
                alt=""
              />
              <a href="https://line.me/R/ti/p/@lsm99queen">
                <img src={Line} alt="" className="left-navbar icon-group2" />
              </a>
            </Col>
            <Col xs={4} className="navbar-center">
              <a href="/">
                <img src={LogoMB} alt="" className="icon-group5 main-icon" />
              </a>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Popup Burger */}
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content bg-modal-bar">
            <div class="modal-header">
              <button
                type="button"
                class="close-btn"
                data-dismiss="modal"
                aria-label="Close"
              >
                <MdClose />
              </button>
            </div>
            <div className="modal-body">
              <div className="session-menu-bar mb-3">
                <a href="https://game.lsm99queen.bet/login ">
                  <img
                    src={BurgerPlayGame}
                    className="Burger-Image"
                    alt="logo"
                  />
                </a>

                <a href="https://game.lsm99queen.bet/login ">
                  <img
                    src={BurgerMoneyback}
                    className="Burger-Image"
                    alt="logo"
                  />
                </a>
              </div>
              <div className="session-menu-bar mb-3">
                <a href="https://game.lsm99queen.bet/login ">
                  <img
                    src={BurgerHistory}
                    className="Burger-Image"
                    alt="logo"
                  />
                </a>
                <a href="/promotion">
                  <img
                    src={BurgerPromotion}
                    className="Burger-Image"
                    alt="logo"
                  />
                </a>
              </div>
              <div className="session-menu-bar mb-3">
                <a href="https://game.lsm99queen.bet/login ">
                  <img
                    src={BurgerAlliance}
                    className="Burger-Image"
                    alt="logo"
                  />
                </a>
                <a
                  href="https://game.lsm99queen.bet/login "
                  className="border-session-menu-bar-2"
                >
                  <img
                    src={BurgerContact}
                    className="Burger-Image"
                    alt="logo"
                  />
                </a>
              </div>
              <div className="box-center">
                <div class="box-img-head">
                  <img src={btnBG} className="img-100" alt="..."></img>
                </div>
              </div>
              <div className="session-menu-bar mb-3">
                <a href="https://game.lsm99queen.bet/login ">
                  <img
                    src={BurgerDownload}
                    className="Burger-Image"
                    alt="logo"
                  />
                </a>
              </div>
              <div className="session-menu-bar mb-3">
                <a href="https://game.lsm99queen.bet/login ">
                  <img
                    src={Logout}
                    className="Burger-Image sapce-burger"
                    alt="logo"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Popup Notification Desktop*/}
      <div
        className="modal fade"
        id="exampleModal1"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content bg-modal-bar">
            <div class="modal-header">
              <button
                type="button"
                class="close-btn"
                data-dismiss="modal"
                aria-label="Close"
              >
                <MdClose />
              </button>
            </div>
            <div className="modal-body">
              <div className="box-center">
                <div class="box-img-head">
                  <img
                    src={TitleNotification}
                    className="img-50"
                    alt="..."
                  ></img>
                </div>
              </div>
              <div className="box-center">
                <div class="box-img-head">
                  <img src={BGNoti} className="img-100" alt="..."></img>
                </div>
              </div>
              <div className="session-menu-bar mb-3">
                <div className="session-nav-category">
                  <div
                    className={classNames("pro-item", {
                      "active-promotion-category": proType === "alls",
                    })}
                    onClick={() => {
                      updatePromotion("alls");
                    }}
                  >
                    <p className="mt-1 title-popup">
                      <b>ทั้งหมด</b>
                    </p>
                  </div>
                  <div
                    className={classNames("pro-item", {
                      "active-promotion-category": proType === "deposit",
                    })}
                    onClick={() => {
                      updatePromotion("deposit");
                    }}
                  >
                    <p className="mt-1 title-popup">
                      <b>ฝากเงิน</b>
                    </p>
                  </div>
                  <div
                    className={classNames("pro-item", {
                      "active-promotion-category": proType === "withdrawn",
                    })}
                    onClick={() => {
                      updatePromotion("withdrawn");
                    }}
                  >
                    <p className="mt-1 title-popup">
                      <b>ถอนเงิน</b>
                    </p>
                  </div>
                  <div
                    className={classNames("pro-item", {
                      "active-promotion-category": proType === "bonus",
                    })}
                    onClick={() => {
                      updatePromotion("bonus");
                    }}
                  >
                    <p className="mt-1 title-popup">
                      <b>โบนัส</b>
                    </p>
                  </div>
                </div>
              </div>
              <div className="session-banner-promotion">
                {bannerPromotion.map((row, index) => (
                  <div
                    className="box-banner-promotion justify-center"
                    key={index}
                  >
                    <h1
                      draggable={false}
                      className="text-notification"
                      alt={row.type}
                    >
                      {row.text}
                    </h1>
                  </div>
                ))}
              </div>
              <div className="box-center sapce-noti">
                <div class="box-img-head">
                  <img src={btnBG} className="img-100" alt="..."></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
